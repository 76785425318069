import '../Scss/includes.scss';
import '@lottiefiles/lottie-player';

window.addEventListener('load', e => {
    const modules = import.meta.glob([
        './Content/*.js'
    ])

    for (const path in modules) {
        void new Promise((_res, _rej) => modules[path]())
    }
})

